<template>
  <!-- <article-detail :is-edit='false'></article-detail> -->
  <div class="createForm_content">
    <!-- 输入框 -->
    <div class="components-input">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/09.png" alt="" />
        <p>发薪企业</p>
      </div>
      <el-row :gutter="20" style="margin-top: 30px">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="企业名称：">
                <el-input
                  v-model="form.entName"
                  size="medium"
                  placeholder="请输入企业名称"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="纳税识别号：">
                <el-input
                  v-model="form.idnNum"
                  size="medium"
                  placeholder="请输入纳税识别号"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="支付通道：">
                <el-select
                  v-model="form.payChan"
                  size="medium"
                  placeholder="全部"
                  style="width: 100%"
                  @change="searchState"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-button
              type="primary"
              size="medium"
              style="background: #6591f1"
              @click="inquire"
              ><i class="el-icon-search" style="margin-right: 7px"></i
              >查询</el-button
            >
            <el-button
              plain
              size="medium"
              style="background: #f7f8fa"
              @click="reset"
              ><i class="el-icon-refresh-left" style="margin-right: 7px"></i
              >重置</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 按钮弹框 -->
    <div class="createForm_button">
      <el-button
        type="primary"
        size="medium"
        @click="newShow"
        style="background: #6591f1"
        icon="el-icon-circle-plus-outline"
        v-if="$store.state['user']['PermissionCodeList'].includes('insertPayEntity')"
        >新增发薪企业</el-button
      >
      <el-button
        type="primary"
        size="medium"
        style="background: #6591f1"
        icon="el-icon-edit"
        @click="educeDerice"
        v-if="$store.state['user']['PermissionCodeList'].includes('exportPayingEntity')"
        >导出</el-button
      >
    </div>
    <!-- 表格 -->
    <div class="components-form">
      <el-table
        :data="pageList.list"
        style="width: 100%"
        v-loading="loadingTable"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
      >
        <el-table-column
          prop="entName"
          label="发薪企业名称"
          align="center"
          show-overflow-tooltip
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="idnNum"
          label="纳税识别号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="法人姓名"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="num"
          label="关联用工企业"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <p v-if="row.num !== null">
              <span
                v-if="row.num != 0"
                style="cursor: pointer; color: #6591f1"
                @click="relevancyShow(row)"
                >{{ row.num }}</span
              >
              <span v-else>{{ row.num }}</span>
            </p>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="payChan"
          label="支付通道"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <div v-if="row.payChan === null">-</div>
            <div>
              <el-tag type="info" v-if="row.payChan == true">已配置</el-tag>
              <el-tag
                type="warning"
                style="color: #e84b4d"
                v-if="row.payChan == false"
                >未配置</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subAccount"
          label="银行账户"
          align="center"
          show-overflow-tooltip
        >
        <template slot-scope="{ row }">
            <span v-if="row.subAccount !== null">
              <span>{{ row.subAccount }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="balance"
          label="账户余额"
          align="center"
          show-overflow-tooltip
        >
        <template slot-scope="{ row }">
            <span v-if="row.balance !== null">
              <span>{{ row.balance }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" show-overflow-tooltip width="160">
          <template v-slot="{ row }">
            <el-tag style="cursor: pointer" size="small" @click="edit(row)"
            v-if="$store.state['user']['PermissionCodeList'].includes('updatePayEntity')">编辑</el-tag
            >
            <el-tag
              style="margin-left: 5px; cursor: pointer"
              size="small "
              v-if="$store.state['user']['PermissionCodeList'].includes('paymentConfiguration')"
              @click="configurationshow(row)"
              >支付配置</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>共{{this.pageList.total}} 家企业 第 {{this.pageList.pageNum}} 页</p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :page-size="form.pageSize"
          :current-page="form.pageIndex"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="pageList.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="names"
      :visible.sync="dialogShowAudit"
      width="540px"
      class="pop-up pop-ups"
      v-if="dialogShowAudit"
      :before-close="newClose"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" :model="forms" :rules="rules" label-width="130px">
          <el-form-item label="企业名称:" prop="entName">
            <el-input
              v-model.trim="forms.entName"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="纳税识别号:" prop="idnNum">
            <el-input
              v-model.trim="forms.idnNum"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人姓名:" prop="name">
            <el-input
              v-model.trim="forms.name"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="newEnterprise"
          :disabled="present"
          style="background: #6591f1"
          >提交</el-button
        >
        <el-button size="medium" @click="newClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="平安银行银企直连配置"
      :visible.sync="deploy"
      width="540px"
      class="pop-up"
      :before-close="configurationClose"
    >
      <p class="line"></p>
      <div class="popContent">
        <el-form ref="forms" :model="forms" :rules="rules" label-width="140px">
          <el-form-item label="户名:" prop="familyName">
            <el-input
              v-model.trim="forms.familyName"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="平安银行账户:" prop="account">
            <el-input
              v-model.trim="forms.account"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="平安银行beclf号:" prop="becif">
            <el-input
              v-model.trim="forms.becif"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="平安银企企业代码:" prop="entCode">
            <el-input
              v-model.trim="forms.entCode"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="费项代码:" prop="expenditureCode">
            <el-input
              v-model.trim="forms.expenditureCode"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位代码:" prop="unitCode">
            <el-input
              v-model.trim="forms.unitCode"
              placeholder="请输入内容"
              maxlength="50"
              size="medium"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="newConfiguration"
          :disabled="audit"
          style="background: #6591f1"
          >提交</el-button
        >
        <el-button size="medium" @click="configurationClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="关联用工企业"
      :visible.sync="relevancy"
      width="1000px"
      class="pop-up"
      :before-close="relevancyClose"
    >
      <p class="line"></p>
      <div class="popContent popContents">
        <div class="arguments">
          <p>
            {{ this.companyDetails.entName }}({{ this.companyDetails.idnNum }})
          </p>
          <div style="display: flex; width: 500px;margin-bottom: 20px;">
            <span style="font-size: 14px; margin: 8px 0 0 30px"
              >用户企业名称：</span
            >
            <el-input
              v-model="affiliatedEnterprise.entName"
              placeholder="请输入用户企业名称"
              suffix-icon="el-icon-search "
              style="width: 50%"
              size="medium"
            >
            </el-input>
            <el-button
              type="primary"
              size="medium"
              style="background: #6591f1;margin-left: 22px;"
              @click="inquires"
              ><i class="el-icon-search" style="margin-right: 7px"></i
              >查询</el-button
            >
          </div>
          <div style="display: flex; width: 500px;margin: 0 0 20px 15px;">
            <span style="font-size: 14px; margin: 8px 0 0 30px"
              >纳税识别号：</span
            >
            <el-input
              v-model="affiliatedEnterprise.idnNum"
              placeholder="请输入纳税识别号"
              suffix-icon="el-icon-search "
              style="width: 50%"
              size="medium"
            >
            </el-input>
            <el-button
              plain
              size="medium"
              style="background: #f7f8fa;margin-left: 22px;"
              @click="resets"
              ><i class="el-icon-refresh-left" style="margin-right: 7px"></i
              >重置</el-button
            >
          </div>
        </div>
        <div class="components-form components-forms">
      <el-table
        :data="pageLists.list"
        style="width: 100%"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
      >
        <el-table-column
          prop="entName"
          label="企业名称"
          align="center"
          show-overflow-tooltip
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="idnNum"
          label="纳税识别号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="法人姓名"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>共{{this.pageLists.total}} 家企业</p>
        <el-pagination
          background
          @size-change="currentChanges"
          @current-change="changePages"
          :page-size="affiliatedEnterprise.pageSize"
          :current-page="affiliatedEnterprise.pageIndex"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="pageLists.total"
        >
        </el-pagination>
      </div>
    </div>
      </div>
      <p class="lines"></p>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="relevancyClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { instance } from "@/utils/request";

export default {
  name: "createForm",
  data() {
    return {
      loadingTable: false,
      present: false,
      audit: false,
      form: {
        pageIndex: 1,
        pageSize: 15,
        entName: "",
        idnNum: "",
        name: "",
        payChan: "1",
        entId: "",
      },
      affiliatedEnterprise:{
        pageIndex: 1,
        pageSize: 15,
        entName: "",
        idnNum: "",
        entId: "",
      },
      pageList: "",
      pageLists: "",
      dialogShowAudit: false,
      deploy: false,
      relevancy: false,
      names: "新增发薪企业",
      timers: null,
      companyDetails: "",
      forms: {
        entName: "",
        idnNum: "",
        name: "",
        id: "",
        familyName: "",
        account: "",
        becif: "",
        entCode: "",
        expenditureCode: "",
        unitCode: "",
        entId: "",
      },
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "3",
          label: "平安银行",
        },
        {
          value: "2",
          label: "未配置",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入法人姓名",
            trigger: ["blur"],
          },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z]{2,36}$/,
            message: "只能输入中/英文且长度在2到36之间",
            trigger: ["blur"],
          },
        ],
        entName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: ["blur"],
          },
        ],
        idnNum: [
          {
            required: true,
            message: "请输入纳税识别号",
            trigger: ["blur"],
          },
        ],
        familyName: [
          {
            required: true,
            message: "请输入户名",
            trigger: ["blur"],
          },
        ],
        account: [
          {
            required: true,
            message: "请输入平安银行账户",
            trigger: ["blur"],
          },
        ],
        becif: [
          {
            required: true,
            message: "请输入平安银行beclf号",
            trigger: ["blur"],
          },
        ],
        entCode: [
          {
            required: true,
            message: "请输入平安银企企业代码",
            trigger: ["blur"],
          },
        ],
        expenditureCode: [
          {
            required: true,
            message: "请输入费项代码",
            trigger: ["blur"],
          },
        ],
        unitCode: [
          {
            required: true,
            message: "请输入单位代码",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  methods: {
     //点击导出
     educeDerice() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.toExportExcel();
      }, 500);
    },
    toExportExcel() {
      const { form } = this;
      const { pageIndex, pageSize, entName, idnNum, payChan } = form;
      instance({
        method: "POST",
        url: "/v1/sysEnt/salaEntListWrite",
        data: {
          pageIndex,
          pageSize,
          entName,
          idnNum,
          payChan,
        },
        responseType: "blob",
      }).then((res) => {

        const link = document.createElement("a"); //创建a标签
        let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // response就是接口返回的文件流
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "发薪企业列表"; // 自定义文件名
        link.click(); // 下载文件
        URL.revokeObjectURL(objectUrl); // 释放内存

      });
    },
    lines() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[0].prepend(lswt_2);
    },
    lines1() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[1].prepend(lswt_2);
    },
    lines2() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[2].prepend(lswt_2);
    },
    newEnterprise() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$refs.forms.validate((valid) => {
          if (valid) {
            this.present = true
            setTimeout(() => {
            this.present = false;
          }, 60000);
            const { forms } = this;
            const { entName, idnNum, name, id } = forms;
            instance({
              method: "post",
              url: "/v1/sysEnt/saveOrUpdatePaySysEnt",
              data: {
                entName,
                idnNum,
                name,
                id,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  if ( this.names == '新增发薪企业') {
                    this.$message.success('新增成功')
                  } else {
                    this.$message.success('编辑成功')
                  }
                  this.getData();
                  this.newClose();
                  this.dialogShowAudit = false
                  setTimeout(() => {
                    this.present = false
                  }, 1000);
                }else{
                  this.present = false
                }
              })
              .catch((err) => {
                this.present = false
              });
          }
        });
      }, 500);
    },
    getData() {
      this.loadingTable = true;
      setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const { form } = this;
      const { pageIndex, pageSize, entName, idnNum, payChan } = form;
      instance({
        method: "post",
        url: "/v1/sysEnt/getSalaEntList",
        data: {
          pageIndex,
          pageSize,
          entName,
          idnNum,
          payChan,
        },
      })
        .then((res) => {
          if (res.success) {
            this.loadingTable = false;
            this.pageList = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
     // 筛选方法
    // 状态
    searchState(val) {
      this.form.pageIndex = 1;
      this.form.payChan = val;
      this.getData();
    },
    // 查询
    inquire() {
      this.form.pageIndex = 1;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 重置页面
    reset() {
      this.form.entName = "";
      this.form.idnNum = "";
      this.form.name = "";
      this.form.payChan = "1";
      this.form.pageIndex = 1;
      this.form.pageSize = 15;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 翻页
    changePage(val) {
      this.form.pageIndex = val;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // f分页
    currentChange(val) {
      this.form.pageSize = val;
      this.form.pageIndex = 1;
      this.getData();
    },
    newShow() {
      console.log(this.forms);
      this.names = "新增发薪企业";
      this.dialogShowAudit = true;
        this.$nextTick(() => {
        if(this.$refs.forms){
          this.$refs.forms.resetFields();//个人喜爱。clearValidate有时候验证清不掉，具体原因暂时没找到
        } 
      })
       for(let key in this.forms){
           this.forms[key]="";  //注意初始值的数据类型为数字、数组等其他类型的，需要在循环外补充。
        
      }
    },
    newClose() {
      this.dialogShowAudit = false;
      this.forms.entName = "";
      this.forms.idnNum = "";
      this.forms.name = "";
      this.forms.id = "";
      console.log(this.forms);
    },
    edit(row) {
      this.forms.entName = row.entName;
      this.forms.idnNum = row.idnNum;
      this.forms.name = row.name;
      this.forms.id = row.id;
      this.names = "编辑发薪企业"; 
      this.dialogShowAudit = true;
    },
    relevancyShow(row) {
      this.companyDetails = row;
      this.getDatas();
    },
    relevancyClose() {
      this.relevancy = false;
    },
    getDatas() {
      const { affiliatedEnterprise } = this;
      const { pageIndex, pageSize, entName, idnNum } = affiliatedEnterprise;
      instance({
        method: "post",
        url: "/v1/sysEnt/getCustomSysEntList",
        data: {
          pageIndex,
          pageSize,
          entName,
          idnNum,
          entId: this.companyDetails.id,
        },
      })
        .then((res) => {
          if (res.success) {
            this.pageLists = res.data;
            this.relevancy = true;
            for (const item of this.pageLists.list) {
              item.balance = Number(item.balance).toFixed(2);
            }
          }
        })
        .catch((err) => {});
    },
     // 查询
     inquires() {
      this.affiliatedEnterprise.pageIndex = 1;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getDatas();
      }, 500);
    },
    // 重置页面
    resets() {
      this.affiliatedEnterprise.entName = "";
      this.affiliatedEnterprise.idnNum = "";
      this.affiliatedEnterprise.pageIndex = 1;
      this.affiliatedEnterprise.pageSize = 15;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getDatas();
      }, 500);
    },
    // 翻页
    changePages(val) {
      this.affiliatedEnterprise.pageIndex = val;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getDatas();
      }, 500);
    },
    // f分页
    currentChanges(val) {
      this.affiliatedEnterprise.pageSize = val;
      this.affiliatedEnterprise.pageIndex = 1;
      this.getDatas();
    },
    configurationshow(row) {
      this.forms.familyName = "";
      this.forms.account = "";
      this.forms.becif = "";
      this.forms.entCode = "";
      this.forms.expenditureCode = "";
      this.forms.unitCode = "";
      this.entId = row.id;
      this.details();
    },
    configurationClose() {
      this.deploy = false;
      this.$nextTick(() => {
        if(this.$refs.forms){
          this.$refs.forms.resetFields();//个人喜爱。clearValidate有时候验证清不掉，具体原因暂时没找到
        } 
      })
    },
    details() {
      instance({
        method: "post",
        url: `/v1/pAcc/getPAccByEntId/${this.entId}`,
      })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.forms.familyName = res?.data?.familyName;
              this.forms.account = res?.data?.account;
              this.forms.becif = res?.data?.becif;
              this.forms.entCode = res?.data?.entCode;
              this.forms.expenditureCode = res?.data?.expenditureCode;
              this.forms.unitCode = res?.data?.unitCode;
            }
            this.deploy = true;
          }
        })
        .catch((err) => {});
    },
    newConfiguration() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$refs.forms.validate((valid) => {
          if (valid) {
            this.audit = true
            setTimeout(() => {
            this.audit = false;
          }, 60000);
        const { forms } = this;
        const { familyName, account, becif, entCode, expenditureCode, unitCode } =
          forms;
        instance({
          method: "post",
          url: "/v1/pAcc/saveOrUpdatePAcc",
          data: {
            familyName,
            account,
            becif,
            entCode,
            expenditureCode,
            unitCode,
            entId: this.entId,
          },
        })
          .then((res) => {
            if (res.code === 200) {
              this.getData();
              this.configurationClose();
              this.deploy = false
              setTimeout(() => {
                  this.audit = false
                }, 1000);
            }else{
              this.audit = false
            }
          })
          .catch((err) => {
            this.audit = false
          });
        }
        });
      }, 500);
    },
  },
  mounted() {
    this.lines();
    this.lines1();
    // this.lines2();
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.createForm_content {
  padding: 20px;
  .components-input {
    width: 100%;
    height: 169px;
    background: #ffffff;
    padding: 20px;
    .tinymceSchedule_title {
      width: 100%;
      border-bottom: 1px solid #ececec;
      display: flex;
      height: 40px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        // line-height: 22px;
      }
      p:nth-child(2) {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
  }
  .createForm_button {
    width: 100%;
    height: 92px;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    .el-button {
      margin-top: 10px;
    }
  }
  .components-form {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    /deep/ .el-table {
      thead {
        height: 50px;
        background: #6591f1;
        font-weight: 600;
      }
      .el-table__cell.is-center {
        text-align: left;
      }
      .cell {
        padding-left: 30px;
      }
    }
    /deep/ .el-tag {
      background: rgba(101, 145, 241, 0.12);
      color: #6591f1;
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .pop-up {
    .line {
      height: 1px;
      background: #ececec;
      margin: 10px 0 30px;
    }
    .lines {
      height: 1px;
      background: #ececec;
    }
    /deep/ .el-dialog__title {
      padding-left: 14px;
      font-size: 16px;
    }
    /deep/ .el-form-item__label {
      font-weight: 400;
      color: #666666;
    }
    /deep/ .el-button {
      font-size: 12px;
      font-weight: 400;
      color: #999;
    }
    /deep/ .el-button--primary {
      color: #fff;
    }
    .createForm_button {
      /deep/ .el-button--primary {
        font-size: 14px;
      }
    }
    /deep/ .has-gutter {
      .cell {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }
    /deep/ .el-dialog__body {
      padding: 0px 40px 20px;
    }
    /deep/ .el-dialog__footer {
      text-align: left;
      padding: 10px 40px 40px;
    }
    /deep/ .el-dialog__header {
      padding: 40px 40px 10px;
      .el-dialog__headerbtn {
        top: 40px;
        right: 40px;
      }
    }
    .popContent {
      padding: 0 26px 0 30px;
    }
    .popContents {
      padding: 0px;
      .arguments {
        padding-left: 30px;
        p {
          font-weight: 500;
          color: #333333;
          margin-bottom: 20px;
        }
      }
      .components-forms{
        padding: 0;
        .pagination{
          margin: 20px 0;
        }
      }
    }
  }
  .pop-ups{
    /deep/ .el-dialog__title{
      padding-left: 14px;
      font-size: 16px;
      border-left: #6591f1 3px solid;
    }
  }
  /deep/ .el-input__inner {
    background: #f7f8fa;
    border: none;
  }
}
</style>

